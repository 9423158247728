// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$eered: #B42A34;
$chalkboard: #192A33;
$white: #ffffff;
$stone: #727F86;
$silver: #E5ECEF;