// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://use.typekit.net/lbb5hem.css');
@import '~@fortawesome/fontawesome-pro/css/all.css';

// Variables
@import 'variables';

// Grid variables
$grid-columns: 12;
$grid-gutter-width: 20px;
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1400px
);
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1340px
);
@import '~bootstrap/scss/bootstrap';

.fade-enter-active, .fade-leave-active {
    transition-duration: 0.2s;
    transition-property: opacity;
}
.fade-enter, .fade-leave-active {
    opacity: 0
}

body{
    //font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    font-family: industry, sans-serif;
    color: #192A33;
    background: #ffffff;
}

h1, .h1{
    transition: font-size 0.2s;
    font-weight: 400;
    font-size: 4.2rem;
    line-height: 4.2rem;
}
h2, .h2{
    transition: font-size 0.2s;
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 3.5rem;
    text-transform: uppercase;
}
h3, .h3{
    transition: font-size 0.2s;
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
}
h4, .h4{
    transition: font-size 0.2s;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 2.8rem;
    text-transform: uppercase;
}
p{
    transition: font-size 0.2s;
    font-size: 1.25rem;
}
button{
    outline: unset;
    border: unset;
}
@media screen and (max-width: 1399px) {
    h1, .h1{
        font-size: 4.5rem;
        line-height: 4.5rem;
    }
    h2, .h2{
        font-size: 3.25rem;
        line-height: 3.25rem;
    }
}
@include media-breakpoint-down(lg){
    p{
        font-size: 1rem;
    }
    h1, .h1{
        font-size: 4.25rem;
        line-height: 4.25rem;
    }
    h2, .h2{
        font-size: 3rem;
        line-height: 3rem;
    }
    h4, .h4{
        font-size: 2rem;
        line-height: 2.1rem;
    }
}
@include media-breakpoint-down(md){
    p{
        font-size: 1rem;
    }
    h1, .h1{
        font-size: 4rem;
        line-height: 4rem;
    }
    h2, .h2{
        font-size: 2.75rem;
        line-height: 2.75rem;
    }
    h3, .h3{
        font-size: 2.25rem;
        line-height: 2.25rem;
    }
    h4, .h4{
        font-size: 1.75rem;
        line-height: 1.85rem;
    }
}
@include media-breakpoint-down(sm){
    h1, .h1{
        font-size: 2.5rem;
        line-height: 2.5rem;
    }
    h2, .h2{
        font-size: 2.2rem;
        line-height: 2.5rem;
    }
    h3, .h3{
        font-size: 2rem;
        line-height: 2rem;
    }
    h4, .h4{
        font-size: 1.5rem;
        line-height: 1.6rem;
    }
}
@include media-breakpoint-down(xs){
    h3, .h3{
        font-size: 1.85rem;
        line-height: 1.85rem;
    }
}
